import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row, Container } from "react-bootstrap"
import FadeIn from "../components/fade-in"

const PrivacyPolicy = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy - Signaclass"
        description="Our privacy policy."
      />
      <FadeIn delay={200} duration={1500}>
        <Container>
          <Row>
            <Col sm="12" className="mt-5 pt-5 mb-3 text-center">
              <h1>
                <strong>Privacy Policy</strong>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="shadow p-4 white">
                <p>
                  Your privacy is important to us, and we respect your right to
                  control your data. We've written this privacy policy to
                  describe what data we process, how we process it and why we
                  process your data.
                </p>
                <p>
                  As a European company, the General Data Protection Regulations
                  regulate how we gather data. Beyond that, we respect your
                  right to privacy and believe companies should reduce intrusive
                  and unnecessary data collection.
                </p>
                <h2>Types of Data Collected and Why</h2>
                <p>
                  We collect some data from you if you signup for our service,
                  use our website or enter information into our forms. Some of
                  this data is collected automatically as part of the
                  fundamental way the internet works; other data may be given
                  freely by you as you use our website.
                </p>
                <p>
                  <strong>
                    We collect data associated with networking, automatically
                    given by your internet service provider and web browser, to
                    facilitate the use of our website or for maintenance
                    purposes:
                  </strong>{" "}
                  IP Address, user-agent, browser information, and usage data.
                </p>
                <p>
                  <strong>
                    We collected data gathered during our signup process or
                    entered into contact forms required to facilitate our
                    service to you and comply with tax or other laws:
                  </strong>{" "}
                  IP Address, User-agent and browser information, usage data,
                  email address, first name, last name, billing address,
                  username, phone number, website, physical address, VAT number
                  or tax ID, any other information you choose to give us during
                  the signup process.
                </p>
                <p>
                  Our service can be used only by adults. Users declare
                  themselves to be an adult under their local law; minors may
                  only use this website and our service with the assistance of a
                  parent or guardian. Under no circumstances do we accept
                  personal information from people under 13. If you believe we
                  have inadvertently collected information directly from a
                  child, please tell us so that we can delete their data.{" "}
                </p>
                <h2>Security &amp; Retention</h2>
                <p>
                  We take security measures to prevent unauthorised access,
                  modification, disclosure or destruction of your data,
                  including firewalls, strong passwords, strong encryption and
                  other methods deemed to improve the security of our data
                  processing. Data is processed at our place of work or by our
                  subprocessors which will be listed below.{" "}
                </p>
                <p>
                  We store and process information only for as long as legally
                  or operationally necessary. Where it is personal data gathered
                  by us to facilitate your services, generally speaking, this
                  means we will delete it after the conclusion of your contact
                  with us.
                </p>
                <p>
                  However, the retention time for your data can be different
                  depending on the data we've gathered. For example, tax law
                  might require us to retain billing information for seven
                  years. We will gladly clarify the specific basis for
                  collecting and processing your data if you have any questions.
                </p>
                <h2>Transfer of Personal Data to Third Countries</h2>
                <p>
                  We may transfer your data to a third country (outside the
                  EU/EEA) for processing through our subprocessors (listed below
                  as third party processors), ensuring that every one of them
                  has a legal basis for the transfer.
                </p>
                <p>
                  We always ensure transfers outside the EU / EEA happen with
                  Standard Contractual Clauses through which third-party
                  processors agree to maintain the standards of EU data
                  protection legislation or under an adequacy decision (for
                  example, Canada).
                </p>
                <p>
                  Our web hosting is located exclusively in EU / EEA data
                  centres.
                </p>
                <h2>Third-Party Processors</h2>
                <p>
                  We use some third party processors to help us deliver our
                  service and process data as necessary.
                </p>
                <p>
                  <strong>Stripe Technology Europe Ltd: </strong>We do not
                  handle payments directly and do not handle payment data such
                  as card numbers or CVC. Stripe process payments for us and
                  integrate with our application so that we can see information
                  related to your stripe business account (should you connect it
                  to us) and help you manage it in response to requests by you.
                  In addition to payment information, the types of data they
                  process for us during setup include device information, IP
                  address, email address, first name, last name, and username.
                  Stripe is based in Ireland, and{" "}
                  <a href="https://stripe.com/ie/privacy">
                    you can read their privacy policy here.
                  </a>
                </p>
                <p>
                  <strong>Google Ireland Limited: </strong>We use Google Ireland
                  Limited for the computing infrastructure and storage required
                  to run our app. We also use a google font to make our website
                  look nice. We use EEA/EU datacenters exclusively. They are
                  based in Ireland, and{" "}
                  <a href="https://cloud.google.com/terms/cloud-privacy-notice">
                    you can read their privacy policy here.
                  </a>
                </p>
                <p>
                  <strong>MongoDB Atlas (MongoDB, Inc.): </strong>
                  MongoDB, Inc. provides MongoDB Atlas, a secure cloud-based
                  database platform, helping us manage and protect your data.
                  They follow strict data protection rules, and we maintain
                  control over your data's use. They are based in the United
                  States, and we use EU/EEA data centres with them.{" "}
                  <a href="https://www.mongodb.com/products/platform/trust/gdpr">
                    Their privacy policy can be found here.
                  </a>
                </p>
                <p>
                  <strong>Mailgun Technologies, Inc: </strong>Mailgun provide a
                  transactional email API that sends automatic emails on our
                  behalf and provides us with facilities to send bulk email.
                  They process your email address, first name, and various data
                  types we have collected depending on the email. They are based
                  in the United States, and we use EU/EEA data centres with
                  them.{" "}
                  <a href="https://www.mailgun.com/legal/privacy-policy/">
                    You can read their privacy policy here.
                  </a>
                </p>
                <p>
                  <strong>Cloudflare Group: </strong>Cloudflare provide services
                  that help keep our website and application secure and fast.
                  They deliver a CDN, which allows accelerated website delivery
                  to you, and a web application firewall, which helps protect us
                  from bots and other threats.{" "}
                  <a href="https://www.cloudflare.com/privacypolicy/">
                    You can read their privacy policy here.
                  </a>
                </p>
                <p>
                  <strong>Conva Ventures, Inc: </strong>We use Conva Ventures
                  Inc. to provide a dashboard that helps us monitor and analyse
                  our web traffic to understand, for example, if we need to
                  increase resources for our website. They process anonymised IP
                  addresses and user-agent data only to indicate which website
                  pages get the most traffic. They are based in Canada and use
                  EU data centres.{" "}
                  <a href="https://usefathom.com/privacy">
                    You can read their privacy policy here.
                  </a>
                </p>
                <h2>Contacting you and Providing your Services</h2>
                <p>
                  We have a contact form on our website where you may enter your
                  email address, phone contact details, and information relating
                  to your company. If you choose to use this, we may contact you
                  using the information you provide to give you more information
                  about our services, setting up a demonstration, or any other
                  matters you may indicate.
                </p>
                <p>
                  When setting up your service, we will process information
                  about you and your business to ensure we provide your service
                  adequately and comply with tax and other regulations that
                  govern transactions between us. This information may include
                  names, addresses, telephone numbers, email addresses, tax IDs
                  and additional information you provide. We then pass this
                  information to Stripe, our payment services processor, who
                  will set up an account under your complete ownership that
                  connects to our service. We will use the same information to
                  manage and retain your login details.
                </p>
                <p>
                  We also have a live chat widget. Freshworks provide this
                  service, and so the information you enter into the live chat
                  box will be processed by them in line with their role in
                  providing us with a live chat service. We have set the highest
                  privacy settings available, including anonymisation of your IP
                  address, and we seek permission before loading the widget.{" "}
                </p>
                <h2>Your rights</h2>
                <p>
                  Under GDPR, you have rights to control your data, and these
                  are:
                </p>
                <ol>
                  <li>The Right to Information</li>
                  <li>The Right of Access</li>
                  <li>The Right to Rectification</li>
                  <li>The Right to Erasure</li>
                  <li>The Right to Restriction of Processing</li>
                  <li>The Right to Data Portability</li>
                  <li>The Right to Object</li>
                  <li>The Right to Avoid Automated Decision-Making</li>
                </ol>
                <p>
                  You may exercise these rights at any time by contacting us.
                </p>
                <h2>Our Use of Cookies</h2>
                <p>
                  We have a dedicated cookie policy <a href="/cookies">here</a>{" "}
                  that describes how we set cookies.
                </p>
                <h2>Changes To This Policy</h2>
                <p>
                  We reserve the right to make changes to this privacy policy by
                  notifying you on this page and, if possible, by sending you an
                  email outlining the changes to our privacy policy. We
                  recommend that you check back often and ensure emails from us
                  do not go into spam if you wish to monitor our privacy
                  policies.
                </p>
                <h2>Scope</h2>
                <p>
                  This privacy policy only refers to the "top-level domain"
                  signaclass.com. Not to its subdomains operated on behalf of
                  data controllers we process for - in that case, you should
                  consult that data controller's privacy policies.
                </p>
                <h2>Complaints &amp; Redress</h2>
                <p>
                  There are supervisory bodies in the European Union regarding
                  data protection. You can contact these bodies in the event of
                  a dispute over this privacy policy or if you think we have
                  mishandled your data. Please consult your local government
                  website for privacy regulators. For example, in Bulgaria, that
                  body, and their contact information, can be found at
                  www.cpdp.bg.
                </p>
                <h2>Our Contact Details</h2>
                <p>
                  Spacely OOD,
                  <br />
                  36 Slavyanska,
                  <br />
                  Plovdiv,
                  <br />
                  Bulgaria,
                  <br />
                  4000,
                  <br />
                  <em>EU VAT No: BG205256411</em>
                </p>
                <h4>Representation for data subjects in the UK</h4>
                <p>
                  We value your privacy and your rights as a data subject and
                  have therefore appointed Prighter Group with its local
                  partners as our privacy representative and your point of
                  contact.
                </p>
                <p>
                  Prighter gives you an easy way to exercise your
                  privacy-related rights (e.g. requests to access or erase
                  personal data).
                </p>
                <p>
                  If you want to contact us via our representative Prighter or
                  make use of your data subject rights, please visit the
                  following website: https://prighter.com/q/11492328689
                </p>
                <em>This privacy policy was last updated 26th September 2023</em>
              </div>
            </Col>
          </Row>
        </Container>
      </FadeIn>
    </Layout>
  )
}

export default PrivacyPolicy
